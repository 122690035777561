/** @jsxRuntime classic */
/** @jsx jsx */
import { graphql, PageProps } from "gatsby"
import { jsx, Box, Container, Flex, Heading, Paragraph } from "theme-ui"

import type { ProductPageQuery } from "../../graphql-types"
import BackgroundSVG from "images/icons/background-light.svg"

import Layout from "components/Layout"
import ProductImageCarousel from "components/ProductImageCarousel"
import RetailerLocator from "components/Locator/RetailerLocator"
import SectionHeading from "components/SectionHeading"

export const query = graphql`
  query ProductPage {
    bulletImage: file(relativePath: { eq: "rifle-2x.png" }) {
      id
      childImageSharp {
        gatsbyImageData(quality: 100, layout: CONSTRAINED)
      }
    }
    boxImage: file(relativePath: { eq: "223-large-box.png" }) {
      id
      childImageSharp {
        gatsbyImageData(quality: 100, layout: CONSTRAINED)
      }
    }
  }
`

interface ProductPageProps extends PageProps {
  data: ProductPageQuery
}

const ProductPage = ({ data, ...props }: ProductPageProps) => {
  const images = [
    {
      image: data.boxImage?.childImageSharp?.gatsbyImageData,
      imageAlt: "rifle ammo box",
    },
    {
      image: data.bulletImage?.childImageSharp?.gatsbyImageData,
      imageAlt: "rifle bullet",
    },
  ]
  return (
    <Layout
      seo={{ title: "Ammunitions", description: "Ammunitions Parent Page" }}
      location={props.location}
    >
      <Box sx={{ position: "relative", overflow: "hidden" }}>
        <BackgroundSVG
          sx={{
            position: "absolute",
            zIndex: -1,
            transformOrigin: "center",
            bottom: 0,
            left: 0,
            width: "auto",
            height: "100%",
            transform: "translate(-40%, 0)",
            "& > g": {
              opacity: 0.2,
            },
            "& *": {
              fill: "#d6d1c4cf",
            },
          }}
        />
        <Box as="section" sx={{ mt: "120px" }}>
          <Container>
            <Flex sx={{ flexDirection: ["column", null, "row"] }}>
              <Box sx={{ flex: ["1 100%", null, "1 60%"], pr: [0, null, 10] }}>
                <Heading sx={{ color: "primary" }}>Aguila Ammunition</Heading>
                <Heading
                  as="h1"
                  variant="hero"
                  sx={{ color: "primary", mb: 8 }}
                >
                  .223 REM FMJ 55 Grain
                </Heading>
                <Paragraph sx={{ mb: 8 }}>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Auctor urna neque et convallis ultrices sed auctor.
                </Paragraph>
                <Heading as="h3" variant="bodyTitle">
                  Full metal jacket (bulk pack)
                </Heading>
                <Heading as="h3" variant="bodyTitle">
                  55 grain
                </Heading>
                <Heading as="h3" variant="bodyTitle">
                  # 1E223108
                </Heading>
                <RetailerLocator
                  sx={{ my: [8, null, 12], display: ["none", null, "flex"] }}
                />
              </Box>
              <Box sx={{ flex: ["1 100%", null, "1 40%"] }}>
                <ProductImageCarousel images={images} />
              </Box>
              <RetailerLocator sx={{ display: ["flex", null, "none"] }} />
            </Flex>
          </Container>
        </Box>

        <Box as="section" sx={{ my: 24 }}>
          <SectionHeading indented>Specifications</SectionHeading>
          <Container sx={{ py: 12 }}>
            <Flex
              sx={{
                flexDirection: ["column", null, "row"],
                ml: [4, null, 24],
                mr: 4,
                gap: 6,
                "& > div:not(:last-of-type)": {
                  pr: 6,
                  position: "relative",
                  "&:after": {
                    display: ["none", null, "block"],
                    content: '""',
                    backgroundColor: "rgba(35,31,32,0.3)",
                    height: "60%",
                    width: "2px",
                    position: "absolute",
                    bottom: 0,
                    right: 0,
                  },
                },
              }}
            >
              <Box
                sx={{
                  borderBottom: ["2px solid rgba(35, 31, 32, 0.3)", null, 0],
                  pl: 5,
                  flex: ["1", null, "1 1 25%"],
                }}
              >
                <Heading as="h2" variant="bodyTitle">
                  Category
                </Heading>
                <Paragraph
                  sx={{ textTransform: "uppercase", py: [0, null, 6] }}
                >
                  Rifle
                </Paragraph>
              </Box>
              <Box
                sx={{
                  borderBottom: ["2px solid rgba(35, 31, 32, 0.3)", null, 0],
                  pl: 5,
                  flex: ["1", null, "1 1 25%"],
                }}
              >
                <Heading as="h2" variant="bodyTitle">
                  Firearm
                </Heading>
                <Paragraph
                  sx={{ textTransform: "uppercase", py: [0, null, 6] }}
                >
                  Rifle
                </Paragraph>
              </Box>
              <Box
                sx={{
                  borderBottom: ["2px solid rgba(35, 31, 32, 0.3)", null, 0],
                  pl: 5,
                  flex: ["1", null, "1 1 25%"],
                }}
              >
                <Heading as="h2" variant="bodyTitle">
                  Application
                </Heading>
                <Paragraph
                  sx={{ textTransform: "uppercase", py: [0, null, 6] }}
                >
                  Target/Range
                </Paragraph>
              </Box>
              <Box
                sx={{
                  borderBottom: ["2px solid rgba(35, 31, 32, 0.3)", null, 0],
                  pl: 5,
                  flex: ["1", null, "1 1 25%"],
                }}
              >
                <Heading as="h2" variant="bodyTitle">
                  Packaging
                </Heading>
                <Paragraph
                  sx={{ textTransform: "uppercase", mb: 0, pt: [0, null, 6] }}
                >
                  1200 Per case
                </Paragraph>
                <Paragraph sx={{ textTransform: "uppercase", m: 0 }}>
                  300 Per Box
                </Paragraph>
              </Box>
            </Flex>
          </Container>
        </Box>

        <Box as="section" sx={{ position: "relative" }}>
          <SectionHeading indented>Ballistics</SectionHeading>
          <Container sx={{ variant: "layout.narrow", py: [8, null, 10] }}>
            <table
              sx={{
                width: "100%",
                margin: "0 auto",
                maxWidth: "590px",
                borderCollapse: "collapse",
                "& td": {
                  verticalAlign: "center",
                  display: ["flex", null, "table-cell"],
                  justifyContent: ["space-between", null, "normal"],
                  float: ["left", null, "none"],
                  width: ["100%", null, "auto"],
                  clear: ["both", null, "unset"],
                  padding: ["10px 0px", null, "10px"],
                  boxSizing: ["border-box", null, "initial"],
                  MozBoxSizing: ["border-box", null, "initial"],
                  WebkitBoxSizing: ["border-box", null, "initial"],
                  textTransform: "uppercase",
                },
                "& td:not(:last-of-type)": {
                  position: "relative",
                  "&:after": {
                    display: ["none", null, "block"],
                    content: '""',
                    backgroundColor: "rgba(35,31,32,0.3)",
                    height: "100%",
                    width: "2px",
                    position: "absolute",
                    bottom: 0,
                    right: 0,
                  },
                },
                "& td:not(:first-of-type)": {
                  pl: [0, null, 12],
                },
                "& thead": {
                  position: ["absolute", null, "static"],
                  left: ["-999em", null, "initial"],
                  top: ["-999em", null, "initial"],
                  width: "100%",
                  textAlign: "left",
                },
                "& thead th": {
                  variant: "text.bodyTitle",
                  padding: ["10px 5px", null, "10px 10px 30px 10px"],
                  pb: 8,
                },
                "& thead th:not(:first-of-type)": {
                  pl: [0, null, 12],
                },
                "& thead tr": {
                  display: ["static", null, "table-row"],
                },
                "& tbody": {
                  display: ["block", null, "revert"],
                },
                "& tr": {
                  display: ["block", null, "table-row"],
                },
                "& tr:not(:last-of-type)": {
                  position: "relative",
                  "&:after": {
                    display: ["block", null, "none"],
                    content: '""',
                    backgroundColor: "rgba(35,31,32,0.3)",
                    height: "2px",
                    width: "100%",
                    position: "absolute",
                    bottom: 0,
                    right: 0,
                    left: 0,
                  },
                },
                "& td:before": {
                  content: "attr(data-th)",
                  display: ["block", null, "none"],
                  fontFamily: "bodyHeavy",
                  margin: "0 0 2px",
                },
                "& tbody tr": {
                  float: ["left", null, "none"],
                  width: ["100%", null, "auto"],
                  marginBottom: ["10px", null, 0],
                },
              }}
            >
              <thead>
                <tr>
                  <th scope="col">
                    Distance
                    <br />
                    (YARDS)
                  </th>
                  <th scope="col">
                    Velocity
                    <br />
                    (FPS)
                  </th>
                  <th scope="col">
                    Energy
                    <br />
                    (FT/LB)
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td data-th="Distance (YARDS)">Muzzle</td>
                  <td data-th="Velocity (FPS)">1150</td>
                  <td data-th="ENERGY (FT/LB)">338</td>
                </tr>
                <tr>
                  <td data-th="Distance (YARDS)">100</td>
                  <td data-th="Velocity (FPS)">922</td>
                  <td data-th="ENERGY (FT/LB)">217</td>
                </tr>
              </tbody>
            </table>
          </Container>
        </Box>
      </Box>
    </Layout>
  )
}

export default ProductPage
