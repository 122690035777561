/** @jsx jsx */
import SwiperCore, { Thumbs, FreeMode, Navigation } from "swiper"
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image"
import { Swiper, SwiperSlide } from "swiper/react"
import { jsx } from "theme-ui"
import { useState } from "react"
import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/thumbs"

SwiperCore.use([FreeMode, Navigation, Thumbs])

export type ProductImage = {
  image: IGatsbyImageData
  imageAlt: string
}

interface ProductImageCarouselProps {
  //enables sx prop to be passed down from parent
  className?: string
  images: ProductImage[]
}

const ProductImageCarousel = ({
  className,
  images,
}: ProductImageCarouselProps) => {
  // store thumbs swiper instance
  const [thumbsSwiper, setThumbsSwiper] = useState<SwiperCore>()

  return (
    <main>
      {/* Main Swiper -> pass thumbs swiper instance */}
      <Swiper
        modules={[Thumbs]}
        thumbs={{ swiper: thumbsSwiper }}
        navigation={true}
        loop={true}
        sx={{
          height: "380px",
          "& .swiper-button-prev:after, & .swiper-button-next:after": {
            color: "primary",
          },
        }}
      >
        {images.map(({ image, imageAlt }, index) => (
          <SwiperSlide key={index}>
            <GatsbyImage
              image={image}
              alt={imageAlt}
              objectFit="contain"
              sx={{ height: "100%", width: "100%" }}
            />
          </SwiperSlide>
        ))}
      </Swiper>

      {/* Thumbs Swiper -> store swiper instance */}
      {/* It is also required to set watchSlidesProgress prop */}
      <Swiper
        modules={[Thumbs]}
        watchSlidesProgress
        onSwiper={setThumbsSwiper}
        slidesPerView={4}
        spaceBetween={20}
        // loop={true}
        sx={{
          height: "140px",
          "& .swiper-slide": {
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
          },
        }}
      >
        {images.map(({ image, imageAlt }, index) => (
          <SwiperSlide key={index}>
            <GatsbyImage
              image={image}
              alt={imageAlt}
              objectFit="contain"
              sx={{ height: "100%", width: "100%" }}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </main>
  )
}

export default ProductImageCarousel
